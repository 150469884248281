import { HammerGestureConfig } from "@angular/platform-browser";

export function compareObjects(prev: any, curr: any): boolean {
  if (prev && curr) {
    return JSON.stringify(prev) === JSON.stringify(curr);
  }
  return false;
}

export class MyHammerConfig extends HammerGestureConfig {
  public overrides = <any>{
    swipe: { direction: 30 } //  Hammer.DIRECTION_ALL in order not to load the whole import * as Hammer from 'hammerjs';
  };
}

export function reloadInPWA(): void {
  if (navigator.serviceWorker) {
    navigator.serviceWorker.getRegistrations().then(registrations => {
      registrations.forEach(registration => {
        registration.unregister();
      });
      window.location.reload();
    });
  } else {
    window.location.reload();
  }

  setTimeout(() => window.location.reload(), 2000);
}
