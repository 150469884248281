import { Injectable, signal } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class LoadProgressService {
  public loadingState = signal(false);

  public setLoading(loading: boolean) {
    this.loadingState.set(loading);
  }
}
