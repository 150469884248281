import { Injectable } from '@angular/core';
import { Apollo, MutationResult } from 'apollo-angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, distinctUntilChanged, map } from 'rxjs/operators';
import { GET_SELECTED_HOBBY_ITEMS, GET_USER_POS } from 'src/app/services/hobbyts-local.service';

import {
  AddFriendGQL,
  CreateHobbyGQL,
  FollowUserGQL,
  GetAllHobbiesDocument,
  GetAllHobbiesGQL,
  GetAllHobbiesQuery,
  GetEventsByUserIdGQL,
  GetEventsByUserIdQuery,
  GetFollowersByUserIdGQL,
  GetFollowersByUserIdQuery,
  GetFollowingByUserIdGQL,
  GetFollowingByUserIdQuery,
  GetFriendsWithMeGQL,
  GetFriendsWithMeQuery,
  GetUserProfileGQL,
  Hobby,
  RemoveFriendGQL,
  UnFollowUserGQL,
  UpdatePositionGQL,
  UpdateUserGQL,
  User
} from 'src/app/services/hobbyts.service';
import { LoadProgressService } from 'src/app/services/load-progress.service';
import { compareObjects } from 'src/app/utils/common.utils';
import { errorHandler, filterDataLoadingErroRxHandler, queryMutationHandler, queryResponseHandler } from 'src/app/utils/handlers.utils';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  public profileObj$ = new BehaviorSubject<Partial<User> | null>(null);

  constructor(
    private apollo: Apollo,
    private addFriend: AddFriendGQL,
    private removeFriend: RemoveFriendGQL,
    private getUserProfileGQL: GetUserProfileGQL,
    private followUserGQL: FollowUserGQL,
    private unFollowUserGQL: UnFollowUserGQL,
    private getEventsByUserIdGQL: GetEventsByUserIdGQL,
    private getFollowingByUserIdGQL: GetFollowingByUserIdGQL,
    private getFollowersByUserIdGQL: GetFollowersByUserIdGQL,
    private updateUserGQL: UpdateUserGQL,
    private getFriendsWithMe: GetFriendsWithMeGQL,
    private updatePositionGQL: UpdatePositionGQL,
    private getAllHobbies: GetAllHobbiesGQL,
    private createHobbyGQL: CreateHobbyGQL,
    private loaderService: LoadProgressService
  ) {}

  public getUserProfile(playerId: string): Observable<GetEventsByUserIdQuery | null> {
    return this.getUserProfileGQL
      .fetch(
        {
          id: playerId
        }
      ).pipe(
        filterDataLoadingErroRxHandler<GetEventsByUserIdQuery>(this.loaderService)
      );
  }

  public followClick(playerId: string, isFollowing: boolean): Observable<MutationResult | null> {
    return this.apollo
      .mutate({
        mutation: this.followUserGQL.document,
        variables: {
          followUnfollowInput: {
            userId: playerId,
            isFollow: isFollowing
          }
        }
      })
      .pipe(
        map((res) => queryMutationHandler(res)),
        catchError((err) => errorHandler(err))
      );
  }

  public unFollowClick(playerId: string, isFollowing: boolean): Observable<MutationResult | null> {
    return this.apollo
      .mutate({
        mutation: this.unFollowUserGQL.document,
        variables: {
          followUnfollowInput: {
            userId: playerId,
            isFollow: isFollowing
          }
        }
      })
      .pipe(
        map((res) => queryMutationHandler(res)),
        catchError((err) => errorHandler(err))
      );
  }

  public followFriend(playerId: string, isFriend: boolean): Observable<MutationResult | null> {
    return this.apollo
      .mutate({
        mutation: this.addFriend.document,
        variables: {
          addRemoveFriendInput: {
            userId: playerId,
            addFriend: isFriend
          }
        }
      })
      .pipe(
        map((res) => queryMutationHandler(res)),
        catchError((err) => errorHandler(err))
      );
  }

  public unFollowFriend(playerId: string, isFriend: boolean): Observable<MutationResult | null> {
    return this.apollo
      .mutate({
        mutation: this.removeFriend.document,
        variables: {
          addRemoveFriendInput: {
            userId: playerId,
            addFriend: isFriend
          }
        }
      })
      .pipe(
        map((res) => queryMutationHandler(res)),
        catchError((err) => errorHandler(err))
      );
  }

  public getEventsByUserId(playerId: string): Observable<GetEventsByUserIdQuery | null> {
    return this.getEventsByUserIdGQL
      .watch(
        {
          getEventByUserInput: {
            skip: 0,
            limit: 10,
            userId: playerId
          }
        }
      )
      .valueChanges.pipe(
        filterDataLoadingErroRxHandler<GetEventsByUserIdQuery>(this.loaderService),
        distinctUntilChanged(compareObjects)
      );
  }

  public getFollowingByUserId(playerId: string): Observable<GetFollowingByUserIdQuery | null> {
    return this.getFollowingByUserIdGQL
      .watch(
        {
          userId: playerId
        },
        { fetchPolicy: 'no-cache' }
      )
      .valueChanges.pipe(
        map((res) => queryResponseHandler<GetFollowingByUserIdQuery>(res)),
        catchError((err) => errorHandler(err))
      );
  }

  public getFollowersByUserId(playerId: string): Observable<GetFollowersByUserIdQuery | null> {
    return this.getFollowersByUserIdGQL
      .watch(
        {
          userId: playerId
        },
        { fetchPolicy: 'no-cache' }
      )
      .valueChanges.pipe(
        map((res) => queryResponseHandler<GetFollowersByUserIdQuery>(res)),
        catchError((err) => errorHandler(err))
      );
  }

  public getFriendsEnrolledInEvent(): Observable<GetFriendsWithMeQuery | null> {
    return this.getFriendsWithMe.watch({}, { fetchPolicy: 'no-cache' }).valueChanges.pipe(
      map((res) => queryResponseHandler<GetFriendsWithMeQuery>(res)),
      catchError((err) => errorHandler(err))
    );
  }

  public updateUser(objToUpdate: Partial<User>): Observable<MutationResult<unknown>> {
    return this.apollo
      .mutate({
        mutation: this.updateUserGQL.document,
        variables: {
          updateUserInput: objToUpdate
        }
      });
  }

  public updatePosition(positions: string[]): Observable<MutationResult<unknown>> {
    return this.apollo.mutate({
      mutation: this.updatePositionGQL.document,
      variables: {
        updatePositionInput: { positions }
      }
    });
  }

  public getHobbies(): Observable<GetAllHobbiesQuery | null> {
    return this.getAllHobbies.fetch().pipe(
      map((res) => queryResponseHandler<GetAllHobbiesQuery>(res)),
      catchError((err) => errorHandler(err))
    );
  }

  public createHobby(newHobby: Hobby): Observable<MutationResult<unknown>> {
    return this.apollo.mutate({
      mutation: this.createHobbyGQL.document,
      variables: {
        newHobby
      },
      update: (store, { data }: any) => {
        const hobbies = store.readQuery({ query: GetAllHobbiesDocument }) as any;
        store.writeQuery({
          query: GetAllHobbiesDocument,
          data: {
            getAllHobbies: {
              hobbies: hobbies.getAllHobbies.hobbies.push(data.createHobby)
            }
          }
        });
      }
    });
  }

  public getSelectedHobbyItems(): Array<Hobby> {
    const data = this.apollo.client.readQuery({
      query: GET_SELECTED_HOBBY_ITEMS
    });
    return data?.selectedHobbyItems ?? [];
  }

  public updateSelectedHobbyItems(hobbies: Array<Hobby>): void {
    this.apollo.client.writeQuery({
      query: GET_SELECTED_HOBBY_ITEMS,
      data: {
        selectedHobbyItems: [...hobbies],
      },
    });
  }

  public getUserPos(): boolean {
    const data = this.apollo.client
        .readQuery({
            query: GET_USER_POS
        });
    return data?.userPos ?? false;
  }

  public updateUserPos(isFromUkraine: boolean): void {
    this.apollo.client.writeQuery({
      query: GET_USER_POS,
      data: {
        userPos: isFromUkraine,
      },
    });
  }
}
