import gql from 'graphql-tag';
// use client.writeQuery and client.readQuery for local data. Remember they are syncronous.
// https://the-guild.dev/graphql/apollo-angular/v1/basics/caching
export const GET_SELECTED_HOBBY_ITEMS = gql`
  query getSelectedHobbyItemsClient {
    selectedHobbyItems
  }
`;

export const GET_USER_POS = gql`
  query getUserPosClient {
    userPos
  }
`
